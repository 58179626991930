import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select-all-checkbox"
export default class extends Controller {

  // this.masterTarget is the 'select all' checkbox
  //
  static targets = ["master"]

  connect() {
    this.syncMasterCheckbox()
  }

  syncOtherCheckboxes() {
    this.otherCheckboxes().forEach((checkbox) => {
      checkbox.checked = this.masterTarget.checked
    })
  }

  otherCheckboxes() {
    // Return all checkboxes except the master checkbox
    return Array.from(this.element.querySelectorAll("input[type=checkbox]")).filter((checkbox) => {
      return checkbox != this.masterTarget
    })
  }

  syncMasterCheckbox() {
    const checkboxes = this.otherCheckboxes()
    const allChecked = checkboxes.every(checkbox => checkbox.checked)
    const noneChecked = checkboxes.every(checkbox => !checkbox.checked)

    if (allChecked) {
      this.masterTarget.checked = true
      this.masterTarget.indeterminate = false
    } else if (noneChecked) {
      this.masterTarget.checked = false
      this.masterTarget.indeterminate = false
    } else {
      this.masterTarget.indeterminate = true
    }
  }
}
