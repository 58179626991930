import { Controller } from "@hotwired/stimulus"
import lottie from "lottie-web"

export default class extends Controller {
  static values = { path: String }

  connect() {
    this.animation = lottie.loadAnimation({
      container: this.element, // The DOM element to render the animation
      renderer: "svg",         // Render type: 'svg', 'canvas', 'html'
      loop: true,              // Should it loop?
      autoplay: true,          // Should it start automatically?
      path: this.pathValue,    // Path to the animation JSON file
    })
  }

  disconnect() {
    this.animation.destroy()  // Clean up the animation when the element is removed
  }
}
